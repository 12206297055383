import React from "react";

import Heading from "$ui/heading";
import Anchor from "$ui/anchor";

import UserLayout from "$components/layout/user";
import EventsPanel from "$components/events/eventsPanel";
import ComplaintsTable from "$components/complaints/complaintsTable";

import { useUser } from "$hooks/auth";

const DashboardPage = () => {
  const { user } = useUser();

  return (
    <UserLayout title="Dashboard">
      <section className="p-10 phone:px-5">
        <Heading variant="tertiary-left">
          Welcome, {user?.fullName.split(" ")[0]}!{" "}
          <span className="text-xs">({user?.role})</span>
        </Heading>

        <p className="text-xs">
          Raise your voice against the illegal acts around you and get them
          resolved through Consumer Rights Organization
        </p>

        <div className="bg-primary bg-opacity-30 py-6 p-8 rounded-2xl text-white my-12">
          <div className="w-1/2 mb-8 phone:w-full">
            <Heading variant="tertiary-white">
              Consumer Rights Organization is ready to help!
            </Heading>
          </div>
          <Anchor href="/complaint" variant="filled">
            File a complaint &nbsp; &rarr;
          </Anchor>
        </div>

        <div className="space-y-4">
          <Heading variant="tertiary-left">My Complaints</Heading>

          <ComplaintsTable />
        </div>
      </section>

      {/* <section className='bg-white py-10 px-4'>
        <Heading variant='tertiary-left'>Upcoming events</Heading>

        <EventsPanel />
      </section> */}
    </UserLayout>
  );
};

export default DashboardPage;
